import {atom, atomFamily} from "recoil";
import {DateType, MilestoneType, People, Project} from "./types";

// TODO : Check if all atoms are still used
// TODO: Remove Recoil state and move to an SSR framework to merge back and front code
// TODO: Refactor atoms

export const ATOM_KEYS = {
    LOADING: "LOADING",
    NEW_DATE_DIALOG: "NEW_DATE_DIALOG",
    EDIT_ALL_DATES: "EDIT_ALL_DATES",
    NEW_MILESTONE_DIALOG: "NEW_MILESTONE_DIALOG",
    NEW_SIRIUS_VEIL_DIALOG: "NEW_SIRIUS_VEIL_DIALOG",
    EDIT_MILESTONE_DIALOG: "EDIT_MILESTONE_DIALOG",
    EDIT_OWNER_DIALOG: "EDIT_OWNER_DIALOG",
    EDIT_TASK_DIALOG: "EDIT_TASK_DIALOG",
    TODAY_BAR_READINESS: "TODAY_BAR_READINESS",
    PEOPLES: "PEOPLES",
    EDIT_TASK_NAME_DIALOG: "EDIT_TASK_NAME_DIALOG",
    NEW_TASK_DIALOG: "NEW_TASK_DIALOG",
    SORTING: "SORTING",
    EXPAND_FILTERS: "EXPAND_FILTERS",
    ANIMATION_QUEUE: "ANIMATION_QUEUE",
};

export const loadingAtom = atom<boolean>({
    key: ATOM_KEYS.LOADING,
    default: true,
});

export type StatusType = "🟢" | "🟠" | "🔴" | "✅";

type DialogAtom = {
    isOpened: boolean,
    taskId?: string,
    planId?: string,
    id?: string,
    date?: Date,
    statusComment?: string,
    name?: string,
    type?: DateType,
    status?: StatusType,
    teamsPlan?: string,
    milestoneType?: MilestoneType,
    dateId?: string,
    owner?: People | null,
    link?: string,
    menuAnchorElement?: HTMLDivElement,
    startDate?: Date | null,
    scheduledDate?: Date | null,
    delayedDate?: Date | null,
    customerMilestone?: boolean,
    projects?: Project[],
    taskName?: string,
    taskLink?: string,
    taskStatus?: StatusType,
    from?: Date | null,
    to?: Date | null,
    risks?: string | null,
    index?: number,
};

export const newSiriusVeilDialogAtom = atom<DialogAtom>({
    key: ATOM_KEYS.NEW_SIRIUS_VEIL_DIALOG,
    default: {
        isOpened: false,
    }
});

export const editMilestoneDialogAtom = atom<DialogAtom>({
    key: ATOM_KEYS.EDIT_MILESTONE_DIALOG,
    default: {
        isOpened: false,
    }
});

export const editOwnerDialogAtom = atom<DialogAtom>({
    key: ATOM_KEYS.EDIT_OWNER_DIALOG,
    default: {
        isOpened: false,
    }
});

export const editTaskDialogAtom = atom<DialogAtom>({
    key: ATOM_KEYS.EDIT_TASK_DIALOG,
    default: {
        isOpened: false,
    }
});

export type FilteringType = "STATUS" | "GROUP_PM" | "ENTITIES" | "CLIENT_NAME";
export type FilterExpansionType = Exclude<FilteringType, "CLIENT_NAME">;
export type FilteringValueTypes = StatusType | string | number;
export type Filtering = Record<FilteringType, Array<string>>

export const expandFiltersAtom = atom<Record<FilterExpansionType, boolean>>({
    key: ATOM_KEYS.EXPAND_FILTERS,
    default: {
        STATUS: true,
        GROUP_PM: false,
        ENTITIES: false,
    }
});

export type SortingType = "ANDON" | "PRIORITY";

export type Sorting = Record<SortingType, boolean>

export const sortingAtom = atom<Sorting>({
    key: ATOM_KEYS.SORTING,
    default: {
        ANDON: false,
        PRIORITY: true,
    }
});

export const animationQueueAtom = atom<Set<string>>({
    key: ATOM_KEYS.ANIMATION_QUEUE,
    default: new Set(),
});

export const draggableTaskRefs = atomFamily<HTMLDivElement, string>({
    key: "draggableTaskRefs",
    default: document.createElement("div"),
});

export const newTaskDialogAtom = atom<DialogAtom>({
    key: ATOM_KEYS.NEW_TASK_DIALOG,
    default: {
        isOpened: false,
    }
});
