import React, {FC} from "react";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";

type IProps = {
    factor: number,
    basis?: string,
    children: React.ReactNode,
    centered?: boolean
    alignedAtStart?: boolean,
    noAlignedStyle?: boolean
};

/**
 * **Responsibility**: TBC
 */
// TODO: Get rid of this, this was a mistake
const FlexGrowBox: FC<IProps> = ({factor, children, basis, centered, alignedAtStart}) => {
    const centeredStyle: SxProps = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };
    const alignedAtStartStyle: SxProps = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    };
    const noAlignedStyle: SxProps = {
        display: "flex",
    };
    return (
        <Box sx={{
            flexGrow: factor,
            flexBasis: basis || 0,
            ...(centered ? centeredStyle : null),
            ...(alignedAtStart ? alignedAtStartStyle : null),
            ...(noAlignedStyle ? noAlignedStyle : null),
        }}>
            {children}
        </Box>
    );
};

export default FlexGrowBox;
