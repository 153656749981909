import React, {FC} from "react";
import {Box} from "@mui/material";
import {useRecoilValue} from "recoil";
import {sortingAtom, StatusType} from "../../constants/atoms";
import TodayBar from "./TodayBar";
import Milestone from "./Milestone";
import Task from "./Task";
import {sortBy} from "lodash";
import TaskDividerComponent from "../presentational/TaskDividerComponent";
import DraggableTask from "../interactive/dragndrop/DraggableTask";
import {SiriusVeil} from "../presentational/SiriusVeil";
import {formatDate} from "../../utils/dates";
import {useSiriusVeils} from "../../hooks/useSiriusVeils";
import {useMilestones} from "../../hooks/useMilestones";
import {useTasks} from "../../hooks/useTasks";

type IProps = {};

/**
 * **Responsibility**: Display a list of projects
 */
const Plan: FC<IProps> = () => {
    const milestones = useMilestones();
    const siriusVeils = useSiriusVeils();
    const filteredTasks = useTasks();
    const sortingValues = useRecoilValue(sortingAtom);

    type TaskForSorting = {
        id: string;
        andon: number;
        lastScheduledDate: string;
        index: number;
    }
    // TODO: Rename this
    const andonSortMapper: Record<StatusType, number> = {
        "✅": 3,
        "🟢": 2,
        "🟠": 1,
        "🔴": 0,
    };
    // TODO: Use a dedicated lib for sorting
    const tasksForSorting = filteredTasks
        .filter(task => !!task)
        .filter(task => !!task.id)
        .map(task => ({
            id: task.id,
            andon: task.status ? andonSortMapper[task.status] : 4,
            lastScheduledDate: formatDate(task.delayedDate) || formatDate(task.scheduledDate) || null,
            index: task.index,
        }) as TaskForSorting);
    const sortingSettings = [
        sortingValues.ANDON ? "andon" : null,
        sortingValues.PRIORITY ? "lastScheduledDate" : null,
        "index"
    ].filter(sortKey => sortKey !== null);
    const sortedTasks = sortBy(
        tasksForSorting,
        sortingSettings
    ) as TaskForSorting[];

    return (
        <Box
            id={"plan-content"}
            sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
            }}>
            {sortedTasks.map(task => (
                <Box key={task.id}>
                    <DraggableTask id={task.id}>
                        <Task id={task.id}/>
                        <TaskDividerComponent/>
                    </DraggableTask>
                </Box>
            ))}
            {siriusVeils.map(siriusVeil => (
                <Box key={siriusVeil.id}>
                    <SiriusVeil id={siriusVeil.id}/>
                </Box>
            ))}
            {milestones.map(milestone => (
                <Box key={milestone.id}>
                    <Milestone id={milestone.id}/>
                </Box>
            ))}
            <TodayBar/>
        </Box>
    );
};

export default Plan;
