import React, {FC, memo, useCallback, useRef, useState} from "react";
import {Box} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import TaskName from "./TaskName";
import TaskStatus from "./TaskStatus";
import TaskOwner from "./TaskOwner";
import TaskLearnings from "./TaskLearnings";
import FlexGrowBox from "../presentational/FlexGrowBox";
import TaskHandle from "./TaskHandle";
import PortKey from "./PortKey";
import TaskTimelineWrapper from "../interactive/click/TaskTimelineWrapper";
import TaskTimeline from "./TaskTimeline";
import TaskEditButton from "../interactive/button/TaskEditButton";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const Task: FC<IProps> = ({id}) => {
    const task = useGetTask(id);
    const ref = useRef<HTMLDivElement>(null);
    const [isHovered, setHoveredState] = useState<boolean>(false);

    const handleMouseEnter = useCallback(() => {
        setHoveredState(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveredState(false);
    }, []);

    if (!task) return null;

    return (
        <Box
            id={id}
            key={id}
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                position: "relative",
                height: "80px",
            }}
            className={`task-content-${id}`}>
            <Box
                ref={ref}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexGrow: 1,
                    flexBasis: "37%"
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <FlexGrowBox alignedAtStart={true} factor={1} basis={"70%"}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                    }}>
                        <TaskHandle id={id} hovered={isHovered}/>
                        <Box sx={{flexGrow: 1, height: "100%"}}>
                            <TaskName id={id}/>
                        </Box>
                        <PortKey id={id}/>
                    </Box>
                </FlexGrowBox>
                <FlexGrowBox centered={true} factor={1} basis={"12%"}>
                    <TaskStatus id={id}/>
                </FlexGrowBox>
                <FlexGrowBox centered={true} factor={1} basis={"18%"}>
                    <TaskOwner id={id}/>
                </FlexGrowBox>
                <TaskEditButton id={id} hovered={isHovered}/>
            </Box>
            <FlexGrowBox factor={1} basis={"43%"}>
                <TaskTimelineWrapper id={id}>
                    <TaskTimeline id={id}/>
                </TaskTimelineWrapper>
            </FlexGrowBox>
            <FlexGrowBox noAlignedStyle={true} factor={1} basis={"20%"}>
                <TaskLearnings id={id}/>
            </FlexGrowBox>
        </Box>
    );
};

export default memo(Task, (prevProps, nextProps) => prevProps.id === nextProps.id);
