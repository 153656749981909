import {useMutation} from "react-query";
import {queryClient} from "../utils/query-client";
import {makeDeleteRequest} from "../utils/http";
import {getDeletePageApiUrl} from "../utils/urls";

// TODO: Find a better way to do this
export const useDeleteTask = (): (id: string) => void => {
    const mutation = useMutation({
        mutationFn: async (id: string) => {
            return makeDeleteRequest({
                url: getDeletePageApiUrl(id, "tasks"),
                body: {},
            })
                .catch(console.error)
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["tasks"]})
    });

    return (id: string) => {
        mutation.mutate(id);
    };
};
