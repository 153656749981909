import React, {FC, memo} from "react";
import {Box} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import ArrowOutIcon from "../icons/ArrowOutIcon";

type IProps = {
    id: string
};

/**
 * **Responsibility**: TBC
 */
const PortKey: FC<IProps> = ({id}) => {
    const task = useGetTask(id);
    if (!task) return null;
    if (!task.link) return null;
    // TODO: Find a more web-standard-ish way to do this
    const onClick = () => window.location.href = `${task.link}`;
    return (
        <Box
            className={"portKey"}
            onClick={onClick}
            sx={theme => ({
                margin: theme.spacing(1),
            })}>
            <ArrowOutIcon/>
        </Box>
    );
};

export default memo(PortKey);
