import {useMutation} from "react-query";
import {makePatchRequest} from "../utils/http";
import {getRedorderTasksApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";

type ReorderTaskInput = {
    dragged: string,
    after: string,
};

export const useReorderTasks = (planId: string): (input: ReorderTaskInput) => void => {
    const mutation = useMutation(
        {
            mutationFn: async (input: ReorderTaskInput) => {
                return makePatchRequest({
                    url: getRedorderTasksApiUrl(planId),
                    body: {
                        dragged: input.dragged,
                        after: input.after,
                    },
                })
                    .catch(console.error)
            },
            onSuccess: () => queryClient.invalidateQueries({queryKey: ["tasks"]})
        }
    )
    return mutation.mutate;
};