import React from "react";
import {MenuItem, Select} from "@mui/material";
import {Text} from "../../presentational/Text";


type IProps = {
    value: boolean | undefined,
    onChange: (status: boolean) => void,
};

const options: Array<{ value: string, label: string }> = [{
    value: "Yes",
    label: "Yes"
}, {
    value: "No",
    label: "No"
}];

const valueMapper = {
    Yes: true,
    No: false
};

export const CustomerMilestoneField: React.FC<IProps> = ({value, onChange}) => {
    return (
        <Select
            className={"customer-milestone-select-field"}
            value={value ? "Yes" : "No"}
            onChange={event => onChange(valueMapper[event.target.value as ("Yes" | "No")])}
            sx={{
                "& > div": {
                    display: "flex",
                    alignItems: "center",
                },
                "& div": {
                    padding: 0,
                },
                "& fieldset": {
                    border: "none",
                }
            }}
        >
            {options.map(option => (
                <MenuItem className={"customer-milestone-menu-item"} key={option.label} value={option.value}>
                    <Text>
                        {option.label}
                    </Text>
                </MenuItem>
            ))}
        </Select>
    )
};