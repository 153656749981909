import {SiriusVeil} from "../constants/types";
import {useEffect} from "react";
import {makeGetRequest} from "../utils/http";
import {getSiriusVeilsApiUrl} from "../utils/urls";
import {useParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {loadingAtom} from "../constants/atoms";
import {useQuery} from "react-query";

// TODO: Find a more elegant way to process back-end data. This has become uncontrollable
export const processedSiriusVeils = (siriusVeils : SiriusVeil[]) => {
    return siriusVeils.map(siriusVeil => ({
        id: siriusVeil.id,
        from: new Date(siriusVeil.from + "T00:00:00"),
        to: new Date(siriusVeil.to + "T00:00:00"),
        title: siriusVeil.title,
    }));
}

const getSiriusVeilsData = (id: string | undefined) => async () => {
    if (!id) throw Error("No ID");
    const siriusVeils = await makeGetRequest<SiriusVeil[]>(getSiriusVeilsApiUrl(id));
    if(siriusVeils) return processedSiriusVeils(siriusVeils)
    return [];
};

export const useSiriusVeils = () : SiriusVeil[] => {
    const {id} = useParams();
    const setLoading = useSetRecoilState(loadingAtom);

    const siriusVeilsQuery = useQuery(
        {
            queryKey: "siriusVeils",
            refetchInterval: 30000,
            queryFn: getSiriusVeilsData(id),
        },
    );

    useEffect(() => {
        if (siriusVeilsQuery.isError) return;
        if (siriusVeilsQuery.isLoading) return;
        setLoading(false);
    }, [setLoading, siriusVeilsQuery]);

    return siriusVeilsQuery.data || [];
};