import React, {FC} from "react";
import {EditableTextField} from "../interactive/fields/EditableTextField";
import {usePlan} from "../../hooks/usePlan";
import {useUpdatePlan} from "../../hooks/useUpdatePlan";

type IProps = {};

/**
 * **Responsibility**: Plan Title Component
 */
const PlanTitle: FC<IProps> = () => {
    const plan = usePlan();
    const setPlan = useUpdatePlan();

    if (!plan) return null;
    return (
        <EditableTextField
            variant={"h1"}
            value={plan.name}
            onChange={value => {
                setPlan({
                    ...plan,
                    name: value,
                })
            }
            }
        />
    );
};
export default PlanTitle;
