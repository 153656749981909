import axios from "axios";
import {toast} from "react-toastify";

type IMakePostRequest<T> = {
    url: string,
    body: T,
};

type IMakePatchRequest<T> = {
    url: string,
    body: T,
}

type IMakeDeleteRequest<T> = {
    url: string,
    body: T,
};

const authHeaders = {
    "X-API-KEY": `${process.env.REACT_APP_API_KEY}`,
    "X-USER-ID": `${localStorage.getItem(`suro-${window.location.origin}-user-id`)}`,
}

export const makePatchRequest = <T, R>({url, body}: IMakePatchRequest<T>) => axios.patch<R>(
    url,
    body,
    {headers: {...authHeaders}}
)
    .then(response => response.data)
    .catch(e => {
        console.error(e);
        toast(`Something went wrong 😢 Call Alex-Adrien. ${e.message}`);
    })

export const makeDeleteRequest = <T, R>({url}: IMakeDeleteRequest<T>) => axios.delete<R>(
    url,
    {headers: {...authHeaders}}
);

export const makePostRequest = <T, R>({url, body}: IMakePostRequest<T>) => axios.post<R>(
    url,
    body,
    {headers: {...authHeaders}}
)
    .then(response => response.data)
    .catch(e => {
        console.error(e);
        toast(`Something went wrong 😢 Call Alex-Adrien. ${e.message}`);
    });

export const makeGetRequest = <R>(url: string, params?: Object) => axios.get<R>(
    url,
    {headers: {...authHeaders}, params: params}
)
    .then(response => response.data)
    .catch(e => {
        console.error(e);
        toast(`Something went wrong 😢 Call Alex-Adrien. ${e.message}`);
    });
