import React, {FC} from "react";
import {Box, Divider} from "@mui/material";
import TitleHeaderColumn from "./TitleHeaderColumn";
import OwnerHeaderColumn from "./OwnerHeaderColumn";
import ColumnTitleComponent from "./ColumnTitleComponent";
import FlexBoxComponent from "./FlexBoxComponent";

type IProps = {};

/**
 * **Responsibility**: Flex display of the two columns in header
 */
const HeaderComponent: FC<IProps> = () => {
    return (
        <Box id={"header-bar"} sx={theme => ({
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.spacing(2),
        })}>
            <Box sx={theme => ({
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
            })}>
                <FlexBoxComponent
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <TitleHeaderColumn/>
                    <OwnerHeaderColumn/>
                </FlexBoxComponent>
            </Box>
            <ColumnTitleComponent/>
            <Divider/>
        </Box>
    );
};

export default HeaderComponent;
