import React, {FC} from "react";
import {useRecoilValue} from "recoil";
import {loadingAtom} from "../../constants/atoms";
import {Loader} from "../presentational/Loader";
import WithHelmet from "../logical/WithHelmet";
import WithDialogs from "../logical/WithDialogs";
import {newTheme} from "../../utils/theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import PlanIfExist from "../logical/PlanIfExist";
import WithLazyLoading from "../logical/WithLazyLoading";

/**
 * **Responsibility: Display a loader or a Responsive Plan Component
 */
const PlanPage: FC = () => {
    const loading = useRecoilValue(loadingAtom);

    return (
        <ThemeProvider theme={newTheme}>
            <CssBaseline/>
            <WithDialogs>
                <WithHelmet>
                    <WithLazyLoading>
                        <Loader loading={loading}/>
                        <PlanIfExist/>
                    </WithLazyLoading>
                </WithHelmet>
            </WithDialogs>
        </ThemeProvider>
    );
};

export default PlanPage;
