import {Plan} from "../constants/types";
import {useMutation} from "react-query";
import {makePatchRequest} from "../utils/http";
import {getEditPlanApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";

export const useUpdatePlan = () => {
    const setPlanMutation = useMutation({
        mutationFn: (plan: Plan) => makePatchRequest({
            url: getEditPlanApiUrl(plan.id),
            body: {
                name: plan.name,
                owner: plan.owner?.email || null,
            }
        }),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["plan"]}),
    });

    return (plan: Plan) => {
        setPlanMutation.mutate(plan);
    };
};