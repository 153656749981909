import React from "react";
import Box from "@mui/material/Box";
import {useGetTask} from "../../../hooks/useGetTask";

type IProps = {
    id: string
    children: React.ReactNode,
};

const TaskTimelineWrapper: React.FC<IProps> = ({id, children}) => {
    const task = useGetTask(id);
    if (!task) return null;
    return (
        <Box
            className={"timeline-box"}
            sx={{
                marginTop: "1px",
                height: "calc(100% - 1px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F7F7F7",
            }}>
            {children}
        </Box>
    )
};

export default TaskTimelineWrapper;
