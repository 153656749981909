import {Project} from "../constants/types";
import {makeGetRequest} from "../utils/http";
import {getProjectApiUrl} from "../utils/urls";
import {useQuery} from "react-query";

export const getProjectData = async () => {
    const projects = await makeGetRequest<Project[]>(getProjectApiUrl());
    if (!projects) throw Error("No Projects");
    return projects || [];
};


export const useProjects = (): Project[] => {

    const projectQuery = useQuery(
        {
            queryKey: "project",
            queryFn: getProjectData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );
    return projectQuery.data || [];
};