import React, {useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Menu} from "./Menu";
import {SBox} from "../../presentational/SBox";
import {FilteringMenuMultiChoiceSection} from "../../presentational/FilteringMenuMultichoiceSection";
import {entityFilters, statusFilters} from "../../../constants/filters";
import {useGroupPMS} from "../../../hooks/useGroupPMS";
import {expandFiltersAtom, FilterExpansionType} from "../../../constants/atoms";
import {usePlan} from "../../../hooks/usePlan";
import {Divider} from "@mui/material";
import {FilterIcon} from "../../icons/FilterIcon";
import {Text} from "../../presentational/Text";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {FilteringMenuBpiSection} from "../../presentational/FilteringMenuBpiSection";

export const FilterMenu: React.FC = () => {
    const ref = useRef();
    const [open, setOpen] = useState<boolean>(false);
    const plan = usePlan()
    const groupPMS = useGroupPMS()

    const expandFilterValues = useRecoilValue(expandFiltersAtom);
    const setExpandFilterValues = useSetRecoilState(expandFiltersAtom);

    const onFilterClick = (type: FilterExpansionType) => setExpandFilterValues({
        ...expandFilterValues,
        [type]: !expandFilterValues[type]
    });

    return (
        <div>
            <Box
                id={"filter-menu-button"} ref={ref} onClick={() => setOpen(true)}
            >
                <SBox
                    sx={theme => ({
                        gap: theme.spacing(0.5),
                        cursor: 'pointer',
                        backgroundColor: open ? "#f7f7f7" : "white",
                    })}
                    bordered
                    padding={[0.5, 1, 0.5, 1]}
                    aligned>
                    <FilterIcon/>
                    <Text buttonSecondaryMedium>
                        Filter
                    </Text>
                </SBox>
            </Box>
            <Menu
                id={"filter-menu"}
                open={open}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
            >
                <SBox padding={[2, 2, 2, 2]} sx={theme => ({
                    maxWidth: "1000px",
                })}>
                    <FilteringMenuMultiChoiceSection
                        className={"filter-menu-status-section"}
                        expansion={expandFilterValues.STATUS}
                        onExpandClick={onFilterClick}
                        sectionTitle={"Status"}
                        type={"STATUS"}
                        columnNumber={plan?.mainSuro ? 8 : 4}
                        options={statusFilters}
                    />
                    {plan?.mainSuro &&
                        <>
                            <Divider/>
                            <FilteringMenuMultiChoiceSection
                                className={"filter-menu-group-pms-section"}
                                expansion={expandFilterValues.GROUP_PM}
                                onExpandClick={onFilterClick}
                                sectionTitle={"Group PM"}
                                type={"GROUP_PM"}
                                columnNumber={4}
                                options={groupPMS.map(pm => ({value: pm as string, label: pm as string}))}
                            />

                            <Divider/>
                            <FilteringMenuMultiChoiceSection
                                className={"filter-menu-entities-section"}
                                expansion={expandFilterValues.ENTITIES}
                                onExpandClick={onFilterClick}
                                sectionTitle={"Entities staffed in the last 30 days"}
                                type={"ENTITIES"}
                                columnNumber={4}
                                options={entityFilters}
                            />
                            <Divider/>
                            <FilteringMenuBpiSection/>
                        </>
                    }
                </SBox>
            </Menu>
        </div>
    )
};