import React, {FC} from "react";
import ResponsivePlanComponent from "../presentational/ResponsivePlanComponent";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const PlanIfExist: FC<IProps> = () => {
    return <ResponsivePlanComponent/>;
};

export default PlanIfExist;
