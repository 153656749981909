import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useFirstAndLastDateInPlan} from "../../hooks/useFirstAndLastDateInPlan";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

type DateLabel = {
    label: string,
    date: Date,
    left: number,
};

// TODO: Improve this
export const Timeline: React.FC = () => {
    const [minDate, maxDate] = useMinMaxDates();
    const [firstDate, lastDate] = useFirstAndLastDateInPlan();
    const timelineRef = useRef<HTMLDivElement | null>(null)
    const [timelinePixels, setTimelinePixels] = useState<number>(0);

    useEffect(() => {
        if (timelineRef !== null) {
            setTimelinePixels(
                timelineRef.current ? timelineRef.current.offsetWidth : 0
            )
        }
    }, [timelineRef.current]);

    if (!minDate) return <></>;
    if (!maxDate) return <></>;
    if (!firstDate) return <></>;
    if (!lastDate) return <></>;

    let labels: DateLabel[] = [];
    const thereIsMoreThanOneMonth = lastDate.getMonth() !== firstDate.getMonth();
    const numberOfDaysBetweenDates = (maxDate.getTime() - minDate.getTime()) / (1000 * 60 * 60 * 24);
    if (firstDate) {
        const firstDateToAdd = new Date(firstDate);
        const percentageOfDate = (firstDateToAdd.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())
        labels.push({
            date: firstDateToAdd,
            label: firstDateToAdd.toLocaleString('en-US', {month: 'long'}),
            left: Math.floor(percentageOfDate * timelinePixels),
        });
    }
    if (thereIsMoreThanOneMonth) {
        for (let i = 0; i < numberOfDaysBetweenDates; i++) {
            const currentDate = new Date(minDate.getTime() + (i * 1000 * 60 * 60 * 24))
            currentDate.setHours(0, 0, 0, 0);
            const percentageOfDate = (currentDate.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())
            if (currentDate.getMonth() === firstDate.getMonth()) continue;
            if (currentDate.getDate() === 1) labels.push({
                date: currentDate,
                left: Math.floor(percentageOfDate * timelinePixels),
                label: currentDate.toLocaleString('en-US', {month: 'long'})
            })
        }
    }

    let skippedIndex = 0
    return (
        <Box ref={timelineRef} sx={theme => ({
            backgroundColor: "#F7F7F7",
            padding: theme.spacing(1),
            flexGrow: 1,
            position: "relative",
            height: "100%",
            overflow: "hidden",
        })}>
            {labels
                .filter(label => label.left + label.label.length*10 < timelinePixels)
                .filter((label, index) => {
                    if (index === 0) return true
                    if (label.left - labels[index - 1].left > labels[index - 1].label.length*8) {
                        skippedIndex = 0;
                        return true;
                    } else {
                        if (skippedIndex > 0) {
                            if (label.left - labels[index - 1 - skippedIndex].left > labels[index - 1 - skippedIndex].label.length*8) {
                                skippedIndex = 0
                                return true;
                            }
                            skippedIndex += 1;
                            return false;
                        }
                        skippedIndex += 1;
                        return false;
                    }
                })
                .map((label, index) => (
                    <Typography
                        align={"left"}
                        variant={"h3"}
                        sx={{
                            fontWeight: "bold",
                            color: "#717171",
                            position: "absolute",
                            left: `${label.left}px`,
                        }}>
                        {label.label}
                    </Typography>
                ))}
        </Box>
    )
};
