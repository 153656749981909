import React, {memo, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {TaskTimelineDate} from "./TaskTimelineDate";
import {useGetTask} from "../../hooks/useGetTask";
import {useReverseCalculation} from "../../hooks/useReverseCalculation";
import {TaskTimelineLine} from "./TaskTimelineLine";

type IProps = {
    id: string;
};

// TODO: Find a more elegant way to position dates in the timeline
const TaskTimeline: React.FC<IProps> = ({id}) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const [wrapperWidth, setWrapperWidth] = useState<number>();
    const task = useGetTask(id);
    const startRef = useRef<HTMLDivElement>();
    const scheduledRef = useRef<HTMLDivElement>();
    const delayedRef = useRef<HTMLDivElement>();
    const reversed = useReverseCalculation(
        startRef,
        scheduledRef,
        delayedRef
    );
    useEffect(() => {
        setWrapperWidth(wrapperRef.current?.clientWidth);
    }, []);
    if (!task) return null;
    const startDate = task.startDate;
    const scheduledDate = task.scheduledDate;
    const delayedDate = task.delayedDate;
    const isDone = task.status === "✅";
    return (
        <Box ref={wrapperRef}
             className={"task-timeline-wrapper"}
             sx={{
                 width: "100%",
                 height: "calc(16px + 4px + 20px + 4px + 16px)",
                 position: "relative",
             }}>
            <TaskTimelineLine
                id={id}
                wrapperWidth={wrapperWidth}/>
            <TaskTimelineDate
                parentRef={startRef}
                type={"Start"}
                date={startDate}
                wrapperWidth={wrapperWidth}/>
            <TaskTimelineDate
                done={!delayedDate && isDone}
                parentRef={scheduledRef}
                reversed={reversed}
                type={"Scheduled"}
                date={scheduledDate}
                wrapperWidth={wrapperWidth}/>
            <TaskTimelineDate
                done={!!delayedDate && isDone}
                parentRef={delayedRef}
                type={"ExpectedLate"}
                date={delayedDate}
                wrapperWidth={wrapperWidth}/>
        </Box>
    )
};

export default memo(TaskTimeline);
