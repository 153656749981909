import React, {FC} from "react";
import {Box, SvgIcon} from "@mui/material";

type IProps = {};

/**
 * **Responsibility**: TBC
 */
const ArchiveIcon: FC<IProps> = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor:"grab",
        }}>
            <SvgIcon width="16" height={"24"} viewBox="0 0 16 24" fill="none" sx={{fontSize: 24}}>
                <rect width="16" height="24" rx="4" fill="#F7F7F7"/>
                <path
                    d="M4.875 6.75C5.49632 6.75 6 6.24632 6 5.625C6 5.00368 5.49632 4.5 4.875 4.5C4.25368 4.5 3.75 5.00368 3.75 5.625C3.75 6.24632 4.25368 6.75 4.875 6.75Z"
                    fill="#B0B0B0"/>
                <path
                    d="M4.875 13C5.49632 13 6 12.4963 6 11.875C6 11.2537 5.49632 10.75 4.875 10.75C4.25368 10.75 3.75 11.2537 3.75 11.875C3.75 12.4963 4.25368 13 4.875 13Z"
                    fill="#B0B0B0"/>
                <path
                    d="M4.875 19.25C5.49632 19.25 6 18.7463 6 18.125C6 17.5037 5.49632 17 4.875 17C4.25368 17 3.75 17.5037 3.75 18.125C3.75 18.7463 4.25368 19.25 4.875 19.25Z"
                    fill="#B0B0B0"/>
                <path
                    d="M11.125 6.75C11.7463 6.75 12.25 6.24632 12.25 5.625C12.25 5.00368 11.7463 4.5 11.125 4.5C10.5037 4.5 10 5.00368 10 5.625C10 6.24632 10.5037 6.75 11.125 6.75Z"
                    fill="#B0B0B0"/>
                <path
                    d="M11.125 13C11.7463 13 12.25 12.4963 12.25 11.875C12.25 11.2537 11.7463 10.75 11.125 10.75C10.5037 10.75 10 11.2537 10 11.875C10 12.4963 10.5037 13 11.125 13Z"
                    fill="#B0B0B0"/>
                <path
                    d="M11.125 19.25C11.7463 19.25 12.25 18.7463 12.25 18.125C12.25 17.5037 11.7463 17 11.125 17C10.5037 17 10 17.5037 10 18.125C10 18.7463 10.5037 19.25 11.125 19.25Z"
                    fill="#B0B0B0"/>
            </SvgIcon>
        </Box>
    );
};

export default ArchiveIcon;
