import React, {memo} from "react";
import Box from "@mui/material/Box";
import {DateType} from "../../constants/types";
import {TaskDateLabel} from "./TaskDateLabel";
import {TaskDateIcon} from "../icons/TaskDateIcon";

type IProps = {
    type: DateType,
    label: string,
    reversed?: boolean,
    done?: boolean,
};

const TaskDate: React.FC<IProps> = ({done, type, label, reversed}) => {
    return (
        <Box sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
        }}>
            <TaskDateLabel label={reversed ? label : ""}/>
            <TaskDateIcon type={type} done={done}/>
            <TaskDateLabel label={reversed ? "" : label}/>
        </Box>
    )
};

export default memo(TaskDate);