import React, {memo} from "react";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {EditIcon} from "../../icons/EditIcon";
import {useOpenEditTaskDialog} from "../../../hooks/useOpenEditTaskDialog";

type IProps = {
    hovered: boolean,
    id: string,
};

const TaskEditButton: React.FC<IProps> = ({hovered, id}) => {
    const openDialog = useOpenEditTaskDialog(id);
    return (
        <Box className={"task-edit-button"} sx={{
            position: "absolute",
            top: "50%",
            left: "33%",
            transform: "translate(-100%, -50%)",
            opacity: hovered ? 1 : 0,
        }}>
            <Button onClick={openDialog} sx={theme => ({
                backgroundColor: "white",
                borderRadius: "3px",
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                border: "1px solid var(--gray-100, #EBEBEB)",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                display: "flex",
                gap: "4px",
                justifyContent: "space-between",
                color: "#717171",
                minWidth: "62px",
                textTransform: "none",
                ":hover": {
                    backgroundColor: "white",
                },
            })}>
                <EditIcon/>
                Edit
            </Button>
        </Box>
    )
};

export default memo(TaskEditButton);
