import React, {FC} from "react";
import {Box} from "@mui/material";

type IProps = {
    children: React.ReactNode[],
    alignItems: "center",
    justifyContent: "space-between",
};

/**
 * **Responsibility**: TBC
 */
const FlexBoxComponent: FC<IProps> = ({children}) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyItems: "stretch",
        }}>
            {children}
        </Box>
    );
};

export default FlexBoxComponent;
