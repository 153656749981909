import {Task} from "../constants/types";
import {useEffect} from "react";
import {makeGetRequest} from "../utils/http";
import {getTasksApiUrl} from "../utils/urls";
import {useParams, useSearchParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {Filtering, loadingAtom, StatusType} from "../constants/atoms";
import {useQuery} from "react-query";

// TODO: Find a more elegant way to process back-end data. This has become uncontrollable
export const processedTasks = (tasks : Task[]) => {
    return tasks.map(task => ({
        id: task.id,
        status: task.status as StatusType,
        delayedDate: task.delayedDate ? new Date(task.delayedDate + "T00:00:00.000Z") : null,
        startDate: task.startDate ? new Date(task.startDate + "T00:00:00.000Z") : null,
        scheduledDate: task.scheduledDate ? new Date(task.scheduledDate + "T00:00:00.000Z") : null,
        name: task.name,
        owner: task.owner,
        statusComment: task.statusComment,
        link: task.link,
        risks: task.risks,
        index: task.index,
        customerMilestone: task.customerMilestone,
        projects: task.projects,
    })) || [];
}

const getTasksData = (id: string | undefined, filteringParams : Filtering) => async () => {
    if (!id) throw Error("No ID");
    const tasks = await makeGetRequest<Task[]>(getTasksApiUrl(id), filteringParams);
    if(tasks) return processedTasks(tasks)
    return [];
};

export const useTasks = () : Task[] => {
    const {id} = useParams();
    const setLoading = useSetRecoilState(loadingAtom);

    const [searchParams] = useSearchParams();

    const filteringParams : Filtering = {
        CLIENT_NAME: searchParams.get('CLIENT_NAME')?.split(",") ?? [],
        STATUS: searchParams.get('STATUS')?.split(",") ?? [],
        GROUP_PM: searchParams.get('GROUP_PM')?.split(",") ?? [],
        ENTITIES: searchParams.get('ENTITIES')?.split(",") ?? [],
    }

    const tasksQuery = useQuery(
        {
            queryKey: ["tasks", searchParams.toString()],
            refetchInterval: 30000,
            queryFn: getTasksData(id, filteringParams),
        },
    );

    useEffect(() => {
        if (tasksQuery.isError) return;
        if (tasksQuery.isLoading) return;
        setLoading(false);
    }, [setLoading, tasksQuery]);

    return tasksQuery.data || [];
};