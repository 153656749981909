import {Filtering, FilteringType, FilteringValueTypes} from "../constants/atoms";
import {useSearchParams} from "react-router-dom";

export const useFiltering = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const filteringValues : Filtering = {
        CLIENT_NAME: searchParams.get('CLIENT_NAME')?.split(",") ?? [],
        STATUS: searchParams.get('STATUS')?.split(",") ?? [],
        GROUP_PM: searchParams.get('GROUP_PM')?.split(",") ?? [],
        ENTITIES: searchParams.get('ENTITIES')?.split(",") ?? [],
    }

    const updateSearchParams = (type: FilteringType, values: FilteringValueTypes[]) => {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        if (values.length > 0) {
            newSearchParams.set(type, values.join(','));
        } else {
            newSearchParams.delete(type);
        }
        setSearchParams(newSearchParams);
    };

    const onFilteringBoxClick = (type: FilteringType, value: string) => {
        const newValues = filteringValues[type].includes(value)
            ? filteringValues[type].filter(item => item !== value)
            : [...filteringValues[type], value];
        updateSearchParams(type, newValues);
    };

    const resetFilterType = (type: FilteringType) => {
        updateSearchParams(type, []);
    };

    return {
        filteringValues,
        onFilteringBoxClick,
        resetFilterType,
    };
};