import {SiriusVeil} from "../constants/types";
import {useMutation} from "react-query";
import {queryClient} from "../utils/query-client";
import {makePostRequest} from "../utils/http";
import {getSiriusVeilApiUrl} from "../utils/urls";
import {usePlan} from "./usePlan";
import {formatDate} from "../utils/dates";

export const useCreateSiriusVeil = (): (siriusVeils: SiriusVeil) => void => {
    const plan = usePlan();
    const mutation = useMutation({
        mutationFn: async (siriusVeil: SiriusVeil) => {
            if (!plan) return;
            await makePostRequest({
                url: getSiriusVeilApiUrl(),
                body: {
                    planId: plan.id,
                    title: siriusVeil.title,
                    from: formatDate(siriusVeil.from),
                    to: formatDate(siriusVeil.to),
                }
            })
        },
        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ["siriusVeils"]
        }),
    });
    return (siriusVeil: SiriusVeil) => {
        mutation.mutate(siriusVeil);
    };
};