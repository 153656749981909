import React, {RefObject, useEffect, useState} from "react";
import {TextField, Typography} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {SxProps} from "@mui/system";

type IProps = {
    variant: Variant,
    value: string,
    onChange: (value: string) => void,
    allowEmpty?: boolean,
    sx?: SxProps,
    multiline?: boolean,
    deportedClickBox?: RefObject<HTMLDivElement | null>,
};

// TODO: Refactor this
export const EditableTextField: React.FC<IProps> = (
    {
        variant,
        value,
        onChange,
        allowEmpty,
        sx,
        multiline = false,
        deportedClickBox,
    }) => {
    const [isFocused, setFocusedState] = useState(false);
    const [name, setName] = useState(value);

    useEffect(() => {
        if (!deportedClickBox) return;

        deportedClickBox.current?.addEventListener("click", () => setFocusedState(true))
    }, [deportedClickBox]);

    useEffect(() => {
        if (isFocused) return;
        if (name === value) return;
        const newNameToSave = (name === "" && !allowEmpty) ? "Untitled" : name;
        setName(newNameToSave);
        onChange(newNameToSave);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, isFocused])

    useEffect(() => {
        if (value === name) return;
        setName(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    if (isFocused) {
        return (
            <TextField
                className={"editable-text-textfield"}
                autoFocus
                sx={theme => ({
                    width: "100%",
                    outline: "none",
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            border: "none",
                        },
                        "&.Mui-focused fieldset": {
                            border: "none",
                        },
                        "&:hover fieldset": {
                            border: "none",
                        },
                    },
                    "& .MuiInputBase-input": {
                        padding: 0,
                        fontSize: theme.typography.h1,
                    }
                })}
                value={name}
                onBlur={() => setFocusedState(false)}
                onChange={e => setName(e.target.value)}
                onKeyDown={e => {
                    if (e.shiftKey) return;
                    if (e.code !== "Enter") return;
                    setFocusedState(false);
                }}
                multiline={multiline}
            />
        )
    }

    return (
        <Typography
            variant={variant}
            onClick={() => setFocusedState(true)}
            sx={sx}
            whiteSpace={"pre-line"}
            align={"left"}
        >
            {name}
        </Typography>
    )
};
