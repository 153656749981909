import {Task} from "../constants/types";
import {useMutation} from "react-query";
import {makePostRequest} from "../utils/http";
import {getMoveUpApiUrl} from "../utils/urls";
import {queryClient} from "../utils/query-client";

export const useUpTask = () => {
    const setPlanMutation = useMutation({
        mutationFn: (task: Task) => makePostRequest({
            url: getMoveUpApiUrl(task.id),
            body: {}
        }),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["tasks"]}),
    });

    return (task: Task) => {
        setPlanMutation.mutate(task);
    };
};