import {Plan} from "../constants/types";
import {makeGetRequest} from "../utils/http";
import {getPlanApiUrl} from "../utils/urls";
import {useQuery} from "react-query";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {loadingAtom} from "../constants/atoms";

function processPlan(plan: Plan) {
    return {
        id: plan.id,
        name: plan.name,
        mainSuro: plan.mainSuro || false,
        owner: plan.owner,
    }
}

const getPlanData = (id: string | undefined) => async () => {
    if (!id) throw Error("No ID");
    const plan = await makeGetRequest<Plan>(getPlanApiUrl(id));
    if (!plan) throw Error("No plan");
    return processPlan(plan);
};

export const usePlan = (): Plan | undefined => {
    const {id} = useParams();
    const setLoading = useSetRecoilState(loadingAtom);
    const planQuery = useQuery(
        {
            queryKey: "plan",
            refetchInterval: 30000,
            queryFn: getPlanData(id),
        },
    );

    useEffect(() => {
        if (planQuery.isError) return;
        if (planQuery.isLoading) return;
        setLoading(false);
    }, [setLoading, planQuery]);


    return planQuery.data || {
        id: "",
        mainSuro: false,
        owner: null,
        name: "",
    };
};