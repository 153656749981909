import React, {useRef} from "react";
import {useRecoilState} from "recoil";
import {animationQueueAtom, newTaskDialogAtom, StatusType} from "../../../constants/atoms";
import {Box, Typography} from "@mui/material";
import {useCreateTask} from "../../../hooks/useCreateTask";
import {StartedIcon} from "../../icons/StartedIcon";
import {ScheduledIcon} from "../../icons/ScheduledIcon";
import {DelayedIcon} from "../../icons/DelayedIcon";
import {DateFieldLine} from "../../presentational/DateFieldLine";
import {SBox} from "../../presentational/SBox";
import {TextField} from "../fields/TextField";
import {OwnerField} from "../fields/OwnerField";
import {StatusField} from "../fields/StatusField";
import {Dialog} from "./Dialog";
import {Text} from "../../presentational/Text";
import {People, Project} from "../../../constants/types";
import {usePlan} from "../../../hooks/usePlan";
import {ProjectsField} from "../fields/ProjectsField";
import {useUpdateTask} from "../../../hooks/useUpdateTask";
import {useTask} from "../../../hooks/useTask";
import {scrollToRef} from "../../../utils/scrollToRef";
import {CustomerMilestoneField} from "../fields/CustomerMilestoneField";

export const DeliverableDialog = () => {
    const [animationQueue, setAnimationQueue] = useRecoilState(animationQueueAtom);
    const plan = usePlan();
    const [dialog, setDialog] = useRecoilState(newTaskDialogAtom);
    const task = useTask(dialog.id || "");
    const createNewTask = useCreateTask();
    const editTask = useUpdateTask();

    const [missingProject, setMissingProject] = React.useState(false);
    const missingProjectRef = useRef(null);

    // TODO: Refactor this
    const setName = (name: string) => setDialog({...dialog, name: name});
    const setStatus = (status: StatusType) => setDialog({...dialog, status: status});
    const setTeamsPlan = (teamsPlan: string) => setDialog({...dialog, teamsPlan: teamsPlan});
    const setOwner = (owner: People | null) => setDialog({...dialog, owner: owner});
    const setDeliverableProjects = (projects: Project[]) => {
        setDialog({...dialog, projects: projects})
        setMissingProject(false);
    };
    const setLink = (link: string) => setDialog({...dialog, link: link});
    const setStartDate = (startDate: Date | null) => setDialog({...dialog, startDate: startDate});
    const setScheduledDate = (scheduledDate: Date | null) => setDialog({...dialog, scheduledDate: scheduledDate});
    const setDelayedDate = (delayedDate: Date | null) => setDialog({...dialog, delayedDate: delayedDate});
    const setCustomerMilestone = (customerMilestone: boolean | undefined) => setDialog({
        ...dialog,
        customerMilestone: customerMilestone
    })
    const onSave = () => {
        if (plan?.mainSuro && (dialog.projects === undefined || dialog.projects.length === 0)) {
            setMissingProject(true);
            scrollToRef(missingProjectRef);
            return;
        }
        if (dialog.id) {
            // TODO: Learn how to setup animation
            if (dialog.status === "✅" && task?.status !== "✅") setAnimationQueue(animationQueue.add(dialog.id));
            editTask({
                id: dialog.id,
                risks: dialog.risks || "",
                name: dialog.name || "",
                status: dialog.status || null,
                statusComment: dialog.teamsPlan || "",
                link: dialog.link || "",
                owner: dialog.owner || null,
                startDate: dialog.startDate || null,
                scheduledDate: dialog.scheduledDate || null,
                delayedDate: dialog.delayedDate || null,
                index: dialog.index || 0,
                customerMilestone: dialog.customerMilestone ?? !!plan?.mainSuro,
                projects: dialog.projects || [],
            });
        } else {
            createNewTask({
                name: dialog.name,
                status: dialog.status,
                teamsPlan: dialog.teamsPlan,
                owner: dialog.owner,
                link: dialog.link,
                startDate: dialog.startDate,
                scheduledDate: dialog.scheduledDate,
                delayedDate: dialog.delayedDate,
                projects: dialog.projects,
                customerMilestone: dialog.customerMilestone ?? !!plan?.mainSuro,
            });
        }
        setDialog({isOpened: false});
    };
    const onClose = () => {
        setDialog({isOpened: false});
        setMissingProject(false);
    };
    return (
        <Dialog
            onSave={onSave}
            close={onClose}
            open={dialog.isOpened}
            id={"add-deliverable-modal"}
            title={"Edit a deliverable"}
        >
            <SBox padding={[1, 3, 3, 3]}>
                {plan?.mainSuro && (
                    <Box className={"dialog-project-field"}>
                        <SBox flex column>
                            <Typography ref={missingProjectRef} sx={theme => ({
                                textAlign: "left",
                            })}>
                                Projects
                            </Typography>
                        </SBox>
                        <SBox bordered flex fullWidth column>
                            <ProjectsField values={dialog.projects || []} onChange={setDeliverableProjects}/>
                        </SBox>
                        {missingProject &&
                            <Typography sx={theme => ({
                                color: "red",
                                textAlign: "left",
                                fontSize: "12px",
                                marginTop: theme.spacing(0.5),
                            })}>
                                You have to select at least one project.
                            </Typography>
                        }
                    </Box>
                )}
                <Box className={"dialog-deliverable-field"}>
                    <SBox flex margin={[2, 0, 0, 0]}>
                        <Text>
                            Deliverable name
                        </Text>
                    </SBox>
                    <SBox bordered flex fullWidth column>
                        <TextField id={"task-name-field"} value={dialog.name || ""} onChange={setName}/>
                    </SBox>
                </Box>
                <Box className={"dialog-status-field"}>
                    <SBox flex margin={[2, 0, 0, 0]}>
                        <Text>
                            Status
                        </Text>
                    </SBox>
                    <SBox bordered flex fullWidth column>
                        <StatusField value={dialog.status} onChange={setStatus}/>
                    </SBox>
                </Box>
                <Box className={"dialog-teams-plan-field"}>
                    <SBox flex margin={[2, 0, 0, 0]}>
                        <Text>
                            Team's plan to reach its milestone
                        </Text>
                    </SBox>
                    <SBox bordered flex fullWidth column>
                        <TextField id={"task-teams-plan-field"} value={dialog.teamsPlan || ""} lines={3}
                                   onChange={setTeamsPlan}/>
                    </SBox>
                </Box>
                <Box className={"dialog-owner-field"}>
                    <SBox flex margin={[2, 0, 0, 0]}>
                        <Text>
                            Owner
                        </Text>
                    </SBox>
                    <SBox bordered flex fullWidth column>
                        <OwnerField value={dialog.owner || null} onChange={setOwner}/>
                    </SBox>
                </Box>
                <Box className={"dialog-link-field"}>
                    <SBox flex margin={[2, 0, 0, 0]}>
                        <Text>
                            Link to macroplan
                        </Text>
                        <SBox margin={[0, 0, 0, 1]}>
                            <Text optional>
                                Facultative
                            </Text>
                        </SBox>
                    </SBox>
                    <SBox bordered flex fullWidth column>
                        <TextField id={"task-link-field"} value={dialog.link || ""} onChange={setLink}/>
                    </SBox>
                </Box>
                <Box className={"dialog-start-date-field"}>
                    <SBox flex column margin={[2, 0, 0, 0]} className={"dialog-start-date-field"}>
                        <DateFieldLine text="Start"
                                       icon={<StartedIcon/>}
                                       date={dialog.startDate || null}
                                       onChange={setStartDate}/>
                    </SBox>
                    <SBox flex column margin={[2, 0, 0, 0]} className={"dialog-scheduled-date-field"}>
                        <DateFieldLine text="Scheduled"
                                       icon={<ScheduledIcon/>}
                                       date={dialog.scheduledDate || null}
                                       onChange={setScheduledDate}/>
                    </SBox>
                    <SBox flex column margin={[2, 0, 0, 0]} className={"dialog-delayed-date-field"}>
                        <DateFieldLine text="Delayed"
                                       icon={<DelayedIcon/>}
                                       date={dialog.delayedDate || null}
                                       onChange={setDelayedDate}/>
                    </SBox>
                </Box>
                {plan?.mainSuro && (
                    <Box className={"dialog-customer-milestone-field"}>
                        <SBox flex margin={[2, 0, 0, 0]}>
                            <Text>
                                Customer milestone
                            </Text>
                        </SBox>
                        <SBox flex margin={[0, 0, 0, 0]}>
                            <Box sx={{textAlign: "left"}}>
                                <Text optional>
                                    A customer milestone comes from a discussion with the customer and not from an
                                    internal
                                    team.
                                </Text>
                            </Box>
                        </SBox>
                        <SBox bordered flex fullWidth column>
                            <CustomerMilestoneField value={dialog.customerMilestone ?? true} onChange={setCustomerMilestone}/>
                        </SBox>
                    </Box>
                )}
            </SBox>
        </Dialog>
    )
};
