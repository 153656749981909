import {People} from "../constants/types";
import {makeGetRequest} from "../utils/http";
import {getPeopleApiUrl} from "../utils/urls";
import {useQuery} from "react-query";

export const getPeopleData = async () => {
    const peoples = await makeGetRequest<People[]>(getPeopleApiUrl());
    if (!peoples) throw Error("No Users");
    return peoples || [];
};

export const usePeoples = (): People[] => {

    const peopleQuery = useQuery(
        {
            queryKey: "people",
            queryFn: getPeopleData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );

    return peopleQuery.data || []
};