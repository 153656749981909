import React, {FC, memo, useRef, useState} from "react";
import {Popover, TextField, Typography} from "@mui/material";
import {useUpdateTask} from "../../hooks/useUpdateTask";
import {useTask} from "../../hooks/useTask";

type IProps = {
    id: string,
};

/**
 * **Responsibility**: TBC
 */
const TaskLearnings: FC<IProps> = ({id}) => {
    const updateTask = useUpdateTask();
    const task = useTask(id);
    const [value, setValue] = useState<string | undefined>(task?.risks);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const anchorRef = useRef<HTMLDivElement | null >(null);
    if (!task) return null;


    const handlePopoverOpen = () => {
        setAnchorEl(anchorRef.current);
    };

    const handlePopoverClose = () => {
        if (value !== task.risks){
            updateTask({
                ...task,
                risks: value ?? "",
            })
        }
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <>
            <Typography
                className={"task-learnings"}
                whiteSpace={"pre-line"}
                ref={anchorRef}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
                sx={theme => ({
                    flexGrow: 1,
                    height: '80px',
                    padding: theme.spacing(1),
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 4,
                    textOverflow: "ellipsis",
                    fontSize: theme.typography.pxToRem(12),
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    cursor: "pointer",
                })}
            >
                {value}
                <Popover
                    onClick={(e)=>{e.stopPropagation();handlePopoverClose()}}
                    id="mouse-over-popover"
                    sx={{
                        display: "flex",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    slotProps={{
                        paper: {
                            style: {
                                marginLeft: "16px",
                            },
                        }
                    }}
                >
                    <TextField
                        className={"editable-text-textfield"}
                        onClick={e=>e.stopPropagation()}
                        onChange={e => setValue(e.target.value)}
                        value={value}
                        multiline={true}
                        minRows={8}
                        autoFocus={true}
                        sx={theme => ({
                            flexGrow: 1,
                            width: "600px",
                            maxHeight: "500px",
                            overflowY: "auto",
                            padding: theme.spacing(0),
                            fontSize: theme.typography.pxToRem(14),
                            outline: "none",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                            }
                        })}
                    />
                </Popover>
            </Typography>

        </>
    )
        ;
};

export default memo(TaskLearnings);
