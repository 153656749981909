import React from "react";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";
import {useGetTask} from "../../hooks/useGetTask";
import {useMinMaxDates} from "../../hooks/useMinMaxDates";

// TODO: Segregate logic and rendering
export const TaskTimelineLine: React.FC<{
    id: string,
    wrapperWidth: number | undefined
}> = ({id, wrapperWidth}) => {
    const task = useGetTask(id);
    const [minDate, maxDate] = useMinMaxDates();
    if (!wrapperWidth) return null;
    if (!task) return null;
    const minDateTime = minDate.getTime();
    const maxDateTime = maxDate.getTime();
    const {startDate, scheduledDate, delayedDate} = task;
    const dateArray = [
        startDate,
        scheduledDate,
        delayedDate
    ].filter(date => date !== null);

    if (dateArray.length < 2) return null;
    const firstDate = dateArray[0];
    if (!firstDate) return null;
    const lastDate = dateArray[dateArray.length - 1];
    if (!lastDate) return null;

    const firstDateTime = firstDate.getTime();
    const lastDatetime = lastDate.getTime();
    const widthPercentage = (lastDatetime - firstDateTime) / (maxDateTime - minDateTime);
    const leftPercentage = (firstDateTime - minDateTime) / (maxDateTime - minDateTime);
    return (
        <Box sx={{
            transform: `translate(${leftPercentage * wrapperWidth}px, 30px)`
        }}>
            <Divider color={"#222222"} sx={{width: widthPercentage * wrapperWidth}}/>
        </Box>
    )
};
