import {newTaskDialogAtom} from "../constants/atoms";
import {useSetRecoilState} from "recoil";
import {useTask} from "./useTask";

// TODO: Rename this
export const useOpenEditTaskDialog = (taskId: string) => {
    const setDialog = useSetRecoilState(newTaskDialogAtom);
    const task = useTask(taskId);

    if (!task) return () => setDialog({isOpened: true});

    return () => setDialog({
        isOpened: true,
        id: task.id,
        name: task.name,
        risks: task.risks,
        status: task.status || undefined,
        teamsPlan: task.statusComment,
        owner: task.owner,
        link: task.link,
        startDate: task.startDate,
        scheduledDate: task.scheduledDate,
        delayedDate: task.delayedDate,
        projects: task.projects,
        customerMilestone: task.customerMilestone ?? undefined,
    });
};
