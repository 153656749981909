import {Milestone, MilestoneType} from "../constants/types";
import {useEffect} from "react";
import {makeGetRequest} from "../utils/http";
import {getMilestonesApiUrl} from "../utils/urls";
import {useParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {loadingAtom} from "../constants/atoms";
import {useQuery} from "react-query";

// TODO: Find a more elegant way to process back-end data. This has become uncontrollable
export const processedMilestones = (milestones : Milestone[]) => {
    return milestones.map(milestone => ({
        id: milestone.id,
        date: new Date(milestone.date + "T00:00:00"),
        name: milestone.name,
        type: milestone.type as MilestoneType,
    }));
}

const getMilestonesData = (id: string | undefined) => async () => {
    if (!id) throw Error("No ID");
    const milestones = await makeGetRequest<Milestone[]>(getMilestonesApiUrl(id));
    if(milestones) return processedMilestones(milestones)
    return [];
};

export const useMilestones = () : Milestone[] => {
    const {id} = useParams();
    const setLoading = useSetRecoilState(loadingAtom);

    const milestonesQuery = useQuery(
        {
            queryKey: "milestones",
            refetchInterval: 30000,
            queryFn: getMilestonesData(id),
        },
    );

    useEffect(() => {
        if (milestonesQuery.isError) return;
        if (milestonesQuery.isLoading) return;
        setLoading(false);
    }, [setLoading, milestonesQuery]);

    return milestonesQuery.data || [];
};