import {useTasks} from "./useTasks";

export const useFirstAndLastDateInPlan = (): (Date | null)[] => {
    const filteredTasks = useTasks()
    let dates: (Date | null)[] = [];
    filteredTasks
        .forEach(task => dates.push(
            task.startDate,
            task.scheduledDate,
            task.delayedDate,
        ));
    if (dates.length < 1) return [];
    const filteredDate = dates.filter(value => value !== null) as Date[];
    const sortedDate = filteredDate
        .sort((a, b) =>
            a.getTime() < b.getTime() ? -1 : 1)
    return [sortedDate[0], sortedDate[sortedDate.length - 1]];
};