import {useSiriusVeils} from "./useSiriusVeils";
import {useTasks} from "./useTasks";
import {useMilestones} from "./useMilestones";

// TODO: Refactor this mess
export const useMinMaxDates = (): Date[] => {
    const milestones = useMilestones();
    const siriusVeils = useSiriusVeils();
    const filteredTasks = useTasks()

    const allDates = [
        ...([] as Array<Date | null>).concat(...filteredTasks
            .map(task => [
                task.startDate,
                task.scheduledDate,
                task.delayedDate,
            ])),
        ...([] as Array<Date>).concat(...siriusVeils.map(veil => [
            veil.from,
            veil.to
        ])),
        ...milestones.map(milestone => milestone.date),
    ]
        .filter(dateOrNull => dateOrNull !== null)
        .sort((a, b) => (a?.getTime() || 0) < (b?.getTime() || 0) ? -1 : 1);
    if (allDates.length < 1) return [
        new Date(),
        new Date(),
    ];
    const minDate = new Date(allDates[0] as Date);
    const maxDate = new Date(allDates[allDates.length - 1] as Date);
    const dateDiffInMillis = maxDate.getTime() - minDate.getTime();
    minDate.setTime(minDate.getTime() - 0.05 * dateDiffInMillis);
    maxDate.setTime(maxDate.getTime() + 0.05 * dateDiffInMillis);

    return [
        minDate,
        maxDate,
    ];
};
