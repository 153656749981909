import React, {FC, useEffect} from "react";
import {queryClient} from "../../utils/query-client";
import {getProjectData} from "../../hooks/useProjects";
import {getGroupPMData} from "../../hooks/useGroupPMS";
import {getPeopleData} from "../../hooks/usePeoples";

type IProps = {
    children: React.ReactNode,
};

/**
 * **Responsibility**: Add React Helmet to DOM
 */
// TODO: Use a proper lib to do that
const WithLazyLoading: FC<IProps> = ({children}) => {

    useEffect(() => {
        queryClient.prefetchQuery(["project"], () => getProjectData());
        queryClient.prefetchQuery(["groupPM"], () => getGroupPMData());
        queryClient.prefetchQuery(["people"], () => getPeopleData());
    }, []);

    return (<>{children}</>);
};

export default WithLazyLoading;
