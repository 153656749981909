import {makeGetRequest} from "../utils/http";
import {getGroupPMApiUrl} from "../utils/urls";
import {useQuery} from "react-query";

export const getGroupPMData = async () => {
    const groupPMS = await makeGetRequest<String[]>(getGroupPMApiUrl());
    if (!groupPMS) throw Error("No Group PMS");
    return groupPMS || [];
};

export const useGroupPMS = (): String[] => {

    const groupPMQuery = useQuery(
        {
            queryKey: "groupPM",
            queryFn: getGroupPMData,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    );

    return groupPMQuery.data || [];
};