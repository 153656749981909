import React, {FC} from "react";
import {Box} from "@mui/material";
import PlanTitle from "./PlanTitle";
import GrindelwaldComponent from "./GrindelwaldComponent";

type IProps = {};

/**
 * **Responsibility**: Flex Display of the plan title and the Grindelwald
 */
const TitleHeaderColumn: FC<IProps> = () => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
        }}>
            <Box sx={theme => ({
                marginBottom: theme.spacing(0.5),
            })}>
                <GrindelwaldComponent/>
            </Box>
            <Box sx={theme => ({
                display: "flex",
                alignItems: "center",
                height:"42px",
            })}>
                <PlanTitle/>
            </Box>
        </Box>
    );
};

export default TitleHeaderColumn;
